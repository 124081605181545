import tour from "./tour.json"
import { Link } from "react-router-dom"
import React, { useEffect } from "react"
import { configureStore, createSlice } from "@reduxjs/toolkit"
import { Provider, useSelector, useDispatch } from "react-redux"

import {
  Form,
  Input,
  Button,
  Slider,
  Select,
  Divider,
  Row,
  Col,
  PageHeader,
} from "antd"

import { DeleteIcon, DownloadIcon } from "./icons"

import "antd/dist/antd.min.css"

import "./Editor.css"

/* RTK Slice for a Tour */

export const tourSlice = createSlice({
  name: "tour",
  initialState: { ...tour },
  reducers: {
    setSceneDescription: (state, action) => {
      const { sceneId, description } = action.payload
      state.scenes[sceneId].description = description
    },
    setNorthAngle: (state, action) => {
      const { sceneId, northAngle } = action.payload
      state.scenes[sceneId].northAngle = northAngle
    },
    addHotspot: (state, action) => {
      const { sceneId } = action.payload
      const defaultHotspot = {
        to: state.sceneMenu[0].children[0],
        angle: {
          pitch: 0,
          yaw: 180,
        },
      }
      state.scenes[sceneId].hotspots.push(defaultHotspot)
    },
    updateHotspot: (state, action) => {
      const { sceneId, hotspotIndex, newHotspotData } = action.payload
      state.scenes[sceneId].hotspots[hotspotIndex] = newHotspotData
    },
    deleteHotspot: (state, action) => {
      const { sceneId, hotspotIndex } = action.payload
      state.scenes[sceneId].hotspots.splice(hotspotIndex, 1)
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload
    },
    deleteImage: (state, action) => {
      const { sceneId, imageIndex } = action.payload
      state.scenes[sceneId].memories.splice(imageIndex, 1)
    },
  },
})

/* RTK Store */

export const store = configureStore({
  reducer: {
    tour: tourSlice.reducer,
  },
})

/* Editor Components */

const Editor = () => (
  <Provider store={store}>
    <EditorUI />
  </Provider>
)

const EditorUI = () => {
  useEffect(() => {
    document.body.id = "editor"
  }, [])
  return (
    <>
      <div className="fixed-header">
        <PageHeader
          className="site-page-header"
          ghost={false}
          title="Virtual Tour Builder"
          extra={[
            <DownloadButton key="downloadButton" />,
            <Link to={"/"} key="linkToTour">
              <Button>Tour Viewer</Button>
            </Link>,
          ]}
        />
      </div>
      <main>
        <div className="sceneEditor">
          {Object.keys(tour.scenes)
            .sort()
            .map((sceneId) => (
              <div key={sceneId}>
                <SceneEditor key={sceneId} sceneId={sceneId} />
                <Divider />
              </div>
            ))}
        </div>
      </main>
    </>
  )
}

const DownloadButton = () => {
  const tourData = useSelector(
    (state) =>
      `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(state.tour, null, 2)
      )}`
  )

  const downloadTourData = async () => {
    const fileName = "tour"
    const href = tourData
    const link = document.createElement("a")
    link.href = href
    link.download = fileName + ".json"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Button onClick={downloadTourData}>
      <DownloadIcon />
      {`Save Tour Data`}
    </Button>
  )
}

const SceneEditor = ({ sceneId }) => {
  const dispatch = useDispatch()
  const scene = useSelector((state) => state.tour.scenes[sceneId])

  const setSceneDescription = (value) => {
    console.log(value)
    dispatch(
      tourSlice.actions.setSceneDescription({
        sceneId: sceneId,
        description: value,
      })
    )
  }

  const setNorthAngle = (value) => {
    dispatch(
      tourSlice.actions.setNorthAngle({ sceneId: sceneId, northAngle: value })
    )
  }

  const addHotspot = () => {
    dispatch(tourSlice.actions.addHotspot({ sceneId: sceneId }))
  }

  const northIndicatorPosition = `${(((scene.northAngle + 0) % 360) / 360) * 100
    }%`
  const eastIndicatorPosition = `${(((scene.northAngle + 90) % 360) / 360) * 100
    }%`
  const southIndicatorPosition = `${(((scene.northAngle + 180) % 360) / 360) * 100
    }%`
  const westIndicatorPosition = `${(((scene.northAngle + 270) % 360) / 360) * 100
    }%`

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      size="small"
      onFieldsChange={(e) => console.log(e)}
    >
      <Form.Item label="Scene ID">{scene.id}</Form.Item>
      <Form.Item label="Description">
        <Input
          defaultValue={scene.description}
          onChange={(e) => setSceneDescription(e.target.value)}
        />
      </Form.Item>
      <Form.Item label="Preview">
        <img
          src={`${process.env.PUBLIC_URL}/assets/panoramas/${scene.panoramaId}.jpg`}
          alt={scene.description}
        />
        <PanoramaVerticalLine
          position={northIndicatorPosition}
          label="North"
          color="red"
        />
        <PanoramaVerticalLine
          position={eastIndicatorPosition}
          label="East"
          color="red"
          style={{ opacity: 0.25 }}
        />
        <PanoramaVerticalLine
          position={southIndicatorPosition}
          label="South"
          color="red"
          style={{ opacity: 0.25 }}
        />
        <PanoramaVerticalLine
          position={westIndicatorPosition}
          label="West"
          color="red"
          style={{ opacity: 0.25 }}
        />
        {scene.hotspots.map((hotspot, i) => (
          <div
            key={`hotspot-${scene.id}-${i}`}
            style={{
              background: "red",
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              position: "absolute",
              left: `${(hotspot.angle.yaw / 360) * 100}%`,
              top: `${((90 - hotspot.angle.pitch) / 180) * 100}%`,
              transform: `translate(-5px, -5px)`,
            }}
          />
        ))}
      </Form.Item>
      <Form.Item label="North Angle">
        <Slider
          min={0}
          max={360}
          onChange={setNorthAngle}
          value={scene.northAngle}
          step={0.0001}
          tipFormatter={(value) => `${value}°`}
        />
      </Form.Item>
      <Form.Item label="Hotspots">
        {scene.hotspots.map((hotspot, i) => (
          <HotspotEditor
            key={`hotspot-editor-${sceneId}-${i}`}
            sceneId={sceneId}
            hotspotIndex={i}
          />
        ))}
        <Button onClick={addHotspot}>New Hotspot</Button>
      </Form.Item>
      {/*
      <Form.Item label="Preview">
        <Link to={`/${sceneId}`}>
          <Button>See in Viewer</Button>
        </Link>
      </Form.Item>
        */}
    </Form>
  )
}

const PanoramaVerticalLine = ({ position, label, color, style }) => {
  return (
    <div
      style={{
        ...style,
        height: "100%",
        position: "absolute",
        left: position,
        top: "0",
        width: "20px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          background: color,
          width: "1px",
          height: "100%",
        }}
      />
      <div
        style={{
          color: color,
          position: "absolute",
          transform: `rotate(90deg) translate(40px, 8px)`,
        }}
      >
        {label}
      </div>
    </div>
  )
}

const HotspotEditor = ({ sceneId, hotspotIndex }) => {
  const dispatch = useDispatch()
  const hotspot = useSelector(
    (state) => state.tour.scenes[sceneId].hotspots[hotspotIndex]
  )
  const destinations = useSelector((state) => state.tour.sceneMenu.flatMap(cat => cat.children).map(sceneId => ({ id: sceneId, name: `${state.tour.sceneMenu.find(cat => cat.children.includes(sceneId)).name} — ${state.tour.scenes[sceneId].description}` })))

  const updateDestination = (value) => {
    const newHotspotData = {
      ...hotspot,
      to: value,
    }
    dispatch(
      tourSlice.actions.updateHotspot({
        sceneId: sceneId,
        hotspotIndex: hotspotIndex,
        newHotspotData: newHotspotData,
      })
    )
  }

  const updatePitch = (value) => {
    const newHotspotData = {
      ...hotspot,
      angle: {
        ...hotspot.angle,
        pitch: value,
      },
    }
    dispatch(
      tourSlice.actions.updateHotspot({
        sceneId: sceneId,
        hotspotIndex: hotspotIndex,
        newHotspotData: newHotspotData,
      })
    )
  }

  const updateYaw = (value) => {
    const newHotspotData = {
      ...hotspot,
      angle: {
        ...hotspot.angle,
        yaw: value,
      },
    }
    dispatch(
      tourSlice.actions.updateHotspot({
        sceneId: sceneId,
        hotspotIndex: hotspotIndex,
        newHotspotData: newHotspotData,
      })
    )
  }

  const deleteHotspot = (value) => {
    dispatch(
      tourSlice.actions.deleteHotspot({
        sceneId: sceneId,
        hotspotIndex: hotspotIndex,
      })
    )
  }

  return (
    <Input.Group>
      <Row gutter={8}>
        <Col span={11}>
          <Form.Item>
            <Select
              placeholder="Select hotspot"
              defaultValue={hotspot.to}
              onChange={updateDestination}
            >
              {destinations.map((destination, i) => (
                <Select.Option key={`destination-${i}`} value={destination.id}>
                  {destination.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="X">
            <Slider
              min={0}
              max={360}
              value={hotspot.angle.yaw}
              step={0.0001}
              tipFormatter={(value) => `${value}°`}
              onChange={updateYaw}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Y">
            <Slider
              min={-90}
              max={90}
              value={hotspot.angle.pitch}
              step={0.0001}
              tipFormatter={(value) => `${value}°`}
              onChange={updatePitch}
            />
          </Form.Item>
        </Col>
        <Col span={1}>
          <Form.Item>
            <DeleteIcon onClick={deleteHotspot} />
          </Form.Item>
        </Col>
      </Row>
    </Input.Group>
  )
}

export default Editor
