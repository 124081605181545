import React from "react"
import { useNavigate } from "react-router-dom"

import { Dropdown, Button, Menu } from "antd"
import { CaretDownIcon } from "./icons"
import "antd/dist/antd.min.css"

import tour from "./tour.json"
import "./PanoramaMenu.css"

/* Utilities */

var smartypants = require("smartypants")

const format = (text) => (
    <span dangerouslySetInnerHTML={{ __html: smartypants.smartypants(text) }} />
)


/* Components */

const PanoramaMenuOverlayItemLabel = ({ sceneId }) => {
    const sceneDescription = tour.scenes[sceneId].description
    const memoryCount = tour.scenes[sceneId].memories.length
    return (
        <span className="scene-name ui-text">
            <span className="memory-description primary">
                {format(sceneDescription)}
            </span>
            <span className="memory-count meta">
                {memoryCount > 0 && `& ${memoryCount} ${memoryCount === 1 ? "picture" : "pictures"}`}
            </span>
        </span>
    )
}

const PanoramaMenuOverlay = () => {
    const menuData = tour.sceneMenu
    const navigate = useNavigate()
    const handleClick = (e) => { navigate(`/${e.key}`) }
    const memoryCount = Object.values(tour.scenes).reduce((memoryCount, scene) => memoryCount + scene.memories.length, 0)

    return (
        <Menu
            onClick={handleClick}
            style={{ position: "fixed" }}
            items={[
                {
                    type: "group",
                    label: "Gallery",
                    children: [{
                        key: "gallery",
                        label: <span className="scene-name ui-text">
                            <span className="memory-description primary">
                                Browse all Photos
                            </span>
                            <span className="memory-count meta">
                                {memoryCount > 0 && `— ${memoryCount} ${memoryCount === 1 ? "picture" : "pictures"}`}
                            </span>
                        </span>
                    }
                    ]
                },
                ...menuData.map((category) => ({
                    type: "group",
                    label: <span className="ui-text"><span className="meta">{category.name}</span></span>,
                    children: category.children.map((sceneId) => ({
                        key: sceneId,
                        label: <PanoramaMenuOverlayItemLabel sceneId={sceneId} />,
                    })),
                })),
            ]}
        />
    )
}

const PanoramaMenu = ({ currentPath }) => {

    const sceneDescription = currentPath === "gallery" ? "Browse all Photos" : tour.scenes.hasOwnProperty(currentPath) ? tour.scenes[currentPath].description : "Menu"
    const sceneCategory = tour.sceneMenu.find((category) => category.children.includes(currentPath)) ? tour.sceneMenu.find((category) => category.children.includes(currentPath)).name : ""

    return (
        <div id="panorama-menu">
            <Dropdown
                key={currentPath}
                overlay={<PanoramaMenuOverlay />}
                overlayStyle={{ position: "fixed" }}
                trigger={["click"]}
            >
                <Button size="large">
                    <span id="active-selection" className="button-text ui-text">
                        {sceneCategory.length > 0 && typeof sceneCategory.length !== "undefined" && <span className="category meta">{sceneCategory}</span>}
                        <span className="description primary">{format(sceneDescription)}</span>
                    </span>
                    <span className="button-icon">
                        <CaretDownIcon />
                    </span>
                </Button>
            </Dropdown>
        </div>
    )
}

export default PanoramaMenu