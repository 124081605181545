// React Icons
import { IconContext } from "react-icons"
import { RiVolumeMuteLine, RiVolumeUpLine, RiArrowRightUpLine, RiArrowRightLine, RiArrowUpLine } from "react-icons/ri"
import { IoMdImages } from "react-icons/io"
import { Md360 } from "react-icons/md"
import { AiOutlineCaretDown, AiOutlineDelete, AiOutlineUpload, AiOutlineDownload } from "react-icons/ai"

// Custom Icon
import { ReactComponent as ContributeImage } from "./icon-contribute-image.svg"

const defaultConfig = {
    size: "1.2em",
    style: { verticalAlign: 'middle', position: "relative", top: "-0.065em" }
}

// Icon Exports
export const ContributeIcon = ({ config }) =>
    /* eslint-disable-next-line */
    <ContributeImage style={{ width: defaultConfig.size, height: "auto", ...defaultConfig.style, top: "-0.15em" }} />


export const AudioOffIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <RiVolumeMuteLine />
    </IconContext.Provider>


export const AudioOnIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <RiVolumeUpLine />
    </IconContext.Provider>


export const LinkOutIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <RiArrowRightUpLine />
    </IconContext.Provider>

export const LinkToIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <RiArrowRightLine />
    </IconContext.Provider>

export const LinkUpIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <RiArrowUpLine />
    </IconContext.Provider>

export const GalleryIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <IoMdImages />
    </IconContext.Provider >


export const PanoramaIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <Md360 />
    </IconContext.Provider>


export const CaretDownIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <AiOutlineCaretDown />
    </IconContext.Provider>


export const DeleteIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <AiOutlineDelete />
    </IconContext.Provider>

export const UploadIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <AiOutlineUpload />
    </IconContext.Provider>

export const DownloadIcon = ({ config }) =>
    <IconContext.Provider value={{ ...defaultConfig, ...config }}>
        <AiOutlineDownload />
    </IconContext.Provider>
