import urlencode from 'urlencode'
import { Button } from "antd"
import "antd/dist/antd.min.css"
import { ContributeIcon } from "./icons"

const ContributeButton = () => {
    const handleClick = (e) => {
        e.preventDefault();
        const address = "timothy.gambell@gmail.com"
        const subject = `Wells Farm Memory`
        const body = `Hi,\n\nTo share pictures or memories about the Farm in Wells, just put them in this email and click send. I'll upload them to the website.\n\nThanks,\nTim`
        window.location.href = `mailto:${address}?subject=${urlencode(subject)}&body=${body.split("\n").map(line => urlencode(line)).join("%0D%0A")}`;
    }

    return (
        <div className="contribute-button">
            <Button size={"large"} style={{ pointerEvents: "all" }} onClick={handleClick}>
                <span className="button-text ui-text collapse"><span className="primary">Contribute Photos or Memories</span></span>
                <span className="button-icon">
                    <ContributeIcon />
                </span>
            </Button>
        </div>
    )
}

export default ContributeButton