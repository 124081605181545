import React, { useEffect, Suspense } from "react"
import { useNavigate } from "react-router-dom"

import { Canvas, useLoader } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"
import { BackSide, TextureLoader } from "three"

import PanoramaMenu from "./PanoramaMenu"

import { Masonry } from "masonic";

import { Button } from "antd"
import "antd/dist/antd.min.css"

import { LinkOutIcon, PanoramaIcon } from "./icons"

/*
import { IconContext } from "react-icons"
import { RiArrowRightUpLine } from "react-icons/ri"
import { Md360 } from "react-icons/md"
*/
import tour from "./tour.json"
import "./Gallery.css"
import ContributeButton from "./ContributeButton";

import CONSTANTS from "./constants.json"

/* constants */

const { resolutions, memoryTypes } = CONSTANTS

/* Utilities */

var smartypants = require("smartypants")

const format = (text) => (
    <span dangerouslySetInnerHTML={{ __html: smartypants.smartypants(text) }} />
)

/* Components */

const Gallery = () => {

    useEffect(() => {
        document.body.id = ""
        const baseTitle = "The Farm in Wells"
        document.title = `${baseTitle} — Gallery`
    }, [])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto",
        })
    }, [])
    return (
        <div id="gallery">
            <PanoramaMenu currentPath={"gallery"} />
            <ImageGallery />
            <ContributeButton />
        </div >
    )
}

const ImageGallery = () => {
    const items = tour.sceneMenu.flatMap(category => category.children).flatMap(sceneId => tour.scenes[sceneId].memories.filter(memory => memory.showInGallery).map(memory => ({ ...memory, sceneId: sceneId })))
    return <Masonry as={"div"} className={"image-gallery"} items={items} render={MasonryCard} columnWidth={500} columnGutter={15 * 3} rowGutter={16 * 2} />
}

const MasonryCard = ({ index, data, width }) => {
    return <Memory memory={data} width={width} />
}

const Memory = ({ memory, width }) => {
    console.log(memory)
    const sceneId = memory.sceneId
    const navigate = useNavigate()

    const captionBlock = (<div className="caption" >
        <figcaption className="text">
            {format(memory.text)}
        </figcaption>
        <div className="cta">
            <Button size="small" onClick={() => navigate(`/${sceneId}`)}>
                {/* 
                <span className="button-icon">
                    <LinkToIcon />
                </span>
                */}
                <span className="button-text ui-text">
                    <span className="location primary">{tour.scenes[sceneId].description}</span>
                </span>
                <span className="button-icon">
                    <LinkOutIcon config={{ style: { top: 0 } }} />
                </span>
            </Button>
        </div>
    </div>)

    switch (memory.type) {
        case memoryTypes.VIDEO:
            return (<div className="memory">
                <div className="media" style={{ position: "relative", width: `${width}`, height: `${width * memory.height / memory.width}px` }} >
                    <Suspense fallback={<div className="loader" />}>
                        <video
                            style={{ width: width, aspectRatio: memory.width / memory.height }}
                            loop
                            muted
                            playsInline
                            autoPlay
                            crossOrigin="anonymous"
                        >
                            <source src={`${process.env.PUBLIC_URL}/assets/media/original/${memory.media}`} />
                        </video>
                    </Suspense>
                </div>
                {captionBlock}
            </div >)
        case memoryTypes.PANORAMA:
            return (
                <figure className="memory">
                    <div className="media" style={{ position: "relative", width: `${width}`, height: `${width * 2 / 3}px` }}>
                        <Suspense fallback={<div className="loader">Loading...</div>}>
                            <GalleryPanoramaViewer width={width} height={width * 2 / 3} src={memory.media} />
                        </Suspense>
                    </div>
                    {captionBlock}
                </figure>
            )
        case memoryTypes.IMAGE:
        case memoryTypes.IMAGESCROLL:
        default:
            return (<figure className="memory">
                <div className="media" style={{ position: "relative", width: `${width}`, height: `${width * memory.height / memory.width}px` }}>
                    <Suspense fallback={<div className="loader" />}>
                        <img
                            style={{ width: width, aspectRatio: memory.width / memory.height }}
                            alt={memory.text}
                            src={`${process.env.PUBLIC_URL}/assets/media/original/${memory.media}`}
                            srcSet={resolutions.map(resolution => `${process.env.PUBLIC_URL}/assets/media/${resolution}/${memory.media} ${resolution}w`).join(", ")}
                        />
                    </Suspense>
                </div>
                {captionBlock}
            </figure >)
    }
}

const GalleryPanoramaViewer = ({ src, width, height }) => {
    const rotateSpeed = window.devicePixelRatio > 1 ? -0.5 : -0.9 // slower rotation on high DPI screens
    const enableZoom = false

    const panoramaResolution = resolutions.find(res => res > width * 3) || resolutions[resolutions.length - 1]
    const panoramaImageSource = `${process.env.PUBLIC_URL}/assets/media/${panoramaResolution}/${src}`

    const texture = useLoader(TextureLoader, panoramaImageSource)

    return (
        <div className="panorama">
            <Canvas style={{ width: `${width}px`, height: `${height}px` }}>
                <mesh scale={[-1, 1, 1]}>
                    <sphereGeometry attach="geometry" args={[500, 60, 40]} />
                    <meshBasicMaterial attach="material" map={texture} side={BackSide} />
                </mesh>
                <OrbitControls
                    autoRotate={true}
                    autoRotateSpeed={0.5}
                    rotateSpeed={rotateSpeed}
                    enableZoom={enableZoom}
                />
            </Canvas>
            <div className="panoramaIcon">
                <PanoramaIcon config={{ size: "2rem", color: "rgba(255, 255, 255, 1)" }} />
            </div>
        </div >
    )
}

export default Gallery