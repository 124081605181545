import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"

import Viewer from "./Viewer"
import Gallery from "./Gallery"
import Editor from "./Editor"

import "@fontsource/source-serif-4/400.css"
import "@fontsource/source-serif-4/variable-full.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/600.css"
import "@fontsource/inter/variable.css"

import "./index.css"

import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path=":sceneId" element={<Viewer />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="edit" element={<Editor />} />
        <Route path="*" element={<Navigate to="outside-arrival" replace />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
